<template>
    <div class="pagination">
        <ul>
            <li
                v-for="(sert, index) in countPagination"
                :class="currentSlide(index)"
                @click="scrollSert(index)"
                :key="sert.MIGX_id"
            ></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "SertificatPagination",
        props: [
            "countPagination",
            "isActiveId"
        ],
        methods:{
            scrollSert(index){
                this.$emit('scroll-sert', index);
            },
            currentSlide(index) {
                return {
                    active : index === this.isActiveId
                }
            },
        }
    }
</script>

<style scoped>

</style>