<template>
    <div
            :class="{click: isClick}">
        <div
                class="item"
                v-for="client in listClient"
                :class="{active: client.act}"
                :style="{backgroundImage: 'url(' + client.img + ')'}"
                :key="client.MIGX_id"
        >

        </div>
    </div>
</template>

<script>
    export default {
        name: "ClientItem",
        props: [
            "listClient",
            "isClick"
        ]
    }
</script>

<style scoped>

</style>