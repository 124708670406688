<template>
    <div class="top">
        <div class="items">
            <div
                    class="item"
                    v-for="otziv_item in listOtziviImg"

                    :key="otziv_item.id"
            >
                <img :src="otziv_item.img" alt="" />
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OtziviImgItem",
        props: [
            "listOtziviImg"
        ]
    }
</script>

<style scoped>

</style>