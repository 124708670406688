<template>
    <div class="page-container" id="slider">
        <SliderItem :list-slider="listSlider" :is-active-id="isActiveId" />
        <PaginationList :list-slider="listSlider" :is-active-id="isActiveId" @scroll-slide="scrollSlide" />
    </div>
</template>



<script>

    const axios = require('axios');

    import SliderItem from './components/SliderItem';
    import PaginationList from './components/PaginationList';

    export default {
        name: "Slider",
        components: {
            SliderItem,
            PaginationList
        },
        data: function () {
            return{
                'isActiveId': 0,
                'listSlider': {}
            }
        },
        created: function () {
            axios.get('../json/slider.json').then((response) => {
                this.listSlider = response.data;
                this.isActiveId = this.listSlider[0].MIGX_id;
            });
        },
        methods: {
            scrollSlide(current_slide_id){
                this.isActiveId = current_slide_id;
            }
        }
    }
</script>



<style scoped>

</style>