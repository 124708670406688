<template>
    <div class="block_our_project" id="project">
        <div class="title">Наши проекты</div>
        <span class="arrow left" :class="{active: isActiveArrowLeft}" @click="scrollProject('left')"></span>
        <span class="arrow right" :class="{active: isActiveArrowRight}" @click="scrollProject('right')"></span>
        <ProjectItem :list-projects="listProjects" :is-click="isClick" />

    </div>
</template>

<script>

    const axios = require('axios/index');

    import ProjectItem from "./components/ProjectItem";

    export default {
        name: "Project",
        components: {
          ProjectItem
        },
        data: function(){
            return {
                "listProjects": {},
                'countProject': 2,
                'isActiveArrowLeft': false,
                'isActiveArrowRight': true,
                'prevProjectId': 1,
                'nextProjectId': 1,
                'isClick': false,
            }
        },
        created: function () {
            axios.get('../json/projects.json').then((response) => {
                this.listProjects = response.data;
                this.nextProjectId = this.countProject - 1;

                for (let i = 0; i < this.listProjects.length; i++) {

                    if ( i <= (this.countProject - 1) ) {
                        this.listProjects[i].act = true;
                    } else{
                        this.listProjects[i].act = false;
                    }
                }
            });
        },
        methods: {
            scrollProject(type){

                if ( type === 'right' )
                {

                    this.nextProjectId += 1;
                    this.prevProjectId += 1;

                    this.listProjects[ this.nextProjectId - (this.countProject ) ].act = false;
                    this.listProjects[ this.nextProjectId ].act = true;

                    if ( this.isActiveArrowLeft === false )
                    {
                        this.isActiveArrowLeft = true;
                    }

                    if( (this.nextProjectId + 1) === this.listProjects.length )
                    {
                        this.isActiveArrowRight = false;
                    }
                }


                if ( type === 'left' )
                {
                    this.nextProjectId -= 1;
                    this.prevProjectId -= 1;

                    this.listProjects[ this.prevProjectId + (this.countProject ) ].act = false;
                    this.listProjects[ this.prevProjectId ].act = true;

                    if ( this.isActiveArrowRight === false )
                    {
                        this.isActiveArrowRight = true;
                    }

                    if ( this.prevProjectId === 0 )
                    {
                        this.isActiveArrowLeft = false;
                    }
                }

                this.isClick = true;
                setTimeout(this.closeClick, 500);
            },

            closeClick(){
                this.isClick = false;
            }
        }
    }
</script>

<style scoped>

</style>