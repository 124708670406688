<template>
    <div class="pagination" :class="noShow(noShowPagination)">
        <ul>
            <li
                v-for="(news, index) in countPagination"
                :class="currentSlide(index)"
                @click="scrollNews(index)"
                :key="news.id"
            ></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "NewsPagination",
        props: [
            "countPagination",
            "isActiveId",
            "noShowPagination"
        ],
        methods:{
            scrollNews(index){
                this.$emit('scroll-news', index);
            },
            currentSlide(index) {
                return {
                    active : index === this.isActiveId
                }
            },
            noShow(noShowPagination) {
                return {
                    no_show : noShowPagination
                }
            },
        }
    }
</script>

<style scoped>

</style>