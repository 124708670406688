<template>
    <div class="block_sertifikat" id="sertificat">
        <div class="title">Сертификаты</div>
        <SertificatItem :list-sertificat="listSertificat" :is-click="isClick" />
        <SertificatPagination :count-pagination="countPagination" :is-active-id="isActiveId" @scroll-sert="scrollSert" />
    </div>
</template>

<script>

    const axios = require('axios/index');

    import SertificatItem from "./components/SertificatItem";
    import SertificatPagination from "./components/SertificatPagination";

    export default {
        name: "Sertificat",
        components: {
            SertificatItem,
            SertificatPagination
        },
        data: function(){
          return {
              'listSertificat': {},
              'countSertificat': 4,
              'countPagination': 0,
              'isClick': false,
              'isActiveId': 0
          }
        },
        created: function () {
            axios.get('../json/sertificat.json').then((response) => {
                this.listSertificat = response.data;
                this.countPagination = this.listSertificat.length - this.countSertificat + 1;

                for (let i = 0; i < this.listSertificat.length; i++) {

                    if ( i <= (this.countSertificat - 1) ) {
                        this.listSertificat[i].act = true;
                    } else{
                        this.listSertificat[i].act = false;
                    }

                }
            });
        },
        methods: {
            scrollSert(index){

                this.isClick = true;
                this.isActiveId = index;

                for (let i = 0; i < this.listSertificat.length; i++) {
                    this.listSertificat[i].act = false;
                }

                for (let i = index; i < (this.countSertificat + index); i++) {
                    this.listSertificat[i].act = true;
                }

                setTimeout(this.closeCick, 500);
            },
            closeCick(){
                this.isClick = false;
            }
        }
    }
</script>

<style scoped>

</style>