// https://jeremyhamm.github.io/vue-slider/

// > ПЕРЕМЕННЫЕ
let jQuery = require('jquery');
export {jQuery};

let isMax768 = true;
if ( document.body.clientWidth <= 768 ) {
    isMax768 = false
}
// <

export {isMax768};

// > ПОДКЛЮЧАЕМ КОМПОНЕНТЫ VUE
import Vue from 'vue'
import Slider from "./Slider";
import Client from "./Client";
import Sertificat from "./Sertificat";
import News from "./News";
import Project from "./Project";
import Otzivi from "./Otzivi";

Vue.config.productionTip = false;

// Добавляем slider
if ( isMax768 ) {
    if( document.getElementById("slider") != null ){
        new Vue({
            render: h => h(Slider)
        }).$mount('#slider');
    }
}

// Добавляем наши партнеры
if( document.getElementById("client") != null ){
    var vue_client = new Vue({
        render: h => h(Client)
    }).$mount('#client');
}

// Добавляем сертификаты
if( document.getElementById("sertificat") != null ){
    new Vue({
        render: h => h(Sertificat)
    }).$mount('#sertificat');
}

// Добавляем новости
if( document.getElementById("news") != null ){
    new Vue({
        render: h => h(News)
    }).$mount('#news');
}

// Добавляем наши проекты
if( document.getElementById("project") != null ){
    new Vue({
        render: h => h(Project)
    }).$mount('#project');
}

// Добавляем отзывы
if( document.getElementById("otzivi") != null ){
    new Vue({
        render: h => h(Otzivi)
    }).$mount('#otzivi');
}
// < #END


// > ОТОБРАЖАЕМ МОБИЛЬНОЕ МЕНЮ
jQuery('.view_mobile_menu').on('click', function () {

    jQuery('#top_menu').addClass('mobile').show(500);
    jQuery(this).hide();
    jQuery('.close_mobile_menu').show();
});

jQuery('.close_mobile_menu').on('click', function () {

    jQuery('#top_menu').addClass('mobile').hide(500);
    jQuery(this).hide();
    jQuery('.view_mobile_menu').show();
});
// < #END


// > CAPCHA
jQuery('.click_captcha').on('click', function () {

    if ( jQuery(this).hasClass('active') ) {
        jQuery(this).removeClass('active');
    } else{
        jQuery(this).addClass('active');
    }
});
// < #END


// > Calculator
jQuery('.block_form.calculator .list_raschet .item').on('click', function () {
    let selector_list_raschet = jQuery(this);

    if ( selector_list_raschet.hasClass('item_1') ) {
        jQuery('.block_rashchet.item_1').addClass('active');
        selector_list_raschet.addClass('active');

        jQuery('.block_form.calculator .list_raschet .item_2').removeClass('active');
        jQuery('.block_form.calculator .block_rashchet.item_2').removeClass('active');

    }

    if ( selector_list_raschet.hasClass('item_2') ) {
        jQuery('.block_rashchet.item_2').addClass('active');
        selector_list_raschet.addClass('active');

        jQuery('.block_form.calculator .list_raschet .item_1').removeClass('active');
        jQuery('.block_form.calculator .block_rashchet.item_1').removeClass('active');
    }
});

jQuery('.block_form.calculator .proschet').on('click', function () {

    let form_selector = jQuery(this).closest('form');
    let Result = 'Заполните поля!';

    // ленточный фундамент
    if ( parseInt( form_selector.find('.block_rashchet.item_1.active').length ) > 0 ) {

        let building_length = parseFloat( form_selector.find(".block_rashchet.item_1.active [name=dl_st]").val().replace(',', '.') );
        let building_width = parseFloat( form_selector.find(".block_rashchet.item_1.active [name=sr_st]").val().replace(',', '.') );
        let lenta_width = parseFloat( form_selector.find(".block_rashchet.item_1.active [name=sr_lent]").val().replace(',', '.') );
        let lenta_height = parseFloat( form_selector.find(".block_rashchet.item_1.active [name=vis_fund]").val().replace(',', '.') );

        let OuterSquare = building_length*building_width;
        let InnerSquare = (building_length-(2*lenta_width))*(building_width-(2*lenta_width));
        if((building_length-(2*lenta_width)) < 0 || (building_width-(2*lenta_width)) < 0) { InnerSquare = 0; }

        Result = (OuterSquare-InnerSquare)*lenta_height;

        if( parseFloat(Result) > 0 ) {
            form_selector.find(".block_rashchet.item_1.active .otvet").text( 'Необходимый объем бетона: ' +  Result + ' м.куб.');
        }
    }

    // Фундаментрая плитка
    if ( parseInt( form_selector.find('.block_rashchet.item_2.active').length ) > 0 ) {

        let building_length = parseFloat( form_selector.find(".block_rashchet.item_2.active [name=dl_st]").val().replace(',', '.') );
        let building_width = parseFloat( form_selector.find(".block_rashchet.item_2.active [name=sr_st]").val().replace(',', '.') );
        let lenta_height = parseFloat( form_selector.find(".block_rashchet.item_2.active [name=vis_fund]").val().replace(',', '.') );

        Result = building_length*building_width*lenta_height;
        if( parseFloat(Result) > 0 ) {
            form_selector.find(".block_rashchet.item_2.active .otvet").text( 'Необходимый объем бетона: ' +  Result + ' м.куб.');
        }
    }
});
// < #END


// > Прикрепляем меню к верху
if (isMax768) {
    jQuery(document).on('scroll', function () {

        let top_scroll = parseInt( jQuery(window).scrollTop() );

        if( top_scroll >= 200 )
        {
            jQuery('#top_menu').addClass('fixed');
        }else{
            jQuery('#top_menu').removeClass('fixed');
        }
    });
}
// < #END


// подключаем увеличение изображений
var Luminous = require('luminous-lightbox').LuminousGallery;
new Luminous(
    document.querySelectorAll(".view_modal"),
    {
        arrowNavigation: true
    },
    {
        caption: function(trigger) {
            return trigger.querySelector("img").getAttribute("alt");
        }
    }
);
// #END