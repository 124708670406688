<template>
    <div class="block_otzivi text" id="otzivi">
        <div class="title">Отзывы о нашей работе</div>
        <p>Предлагаем ознакомиться с отзывами наших клиентов о доставке бетона. Вы также можете оставить свой отзыв о нашей работе, отправив его на почту <a href="mailto:info@tambovbeton.ru">info@tambovbeton.ru</a>.</p>

        <OtziviImgItem :list-otzivi-img="listOtziviImg" />

        <div class="bottom">
            <OtziviTextItem :list-otzivi-text="listOtziviText" :is-click="isClick" />
            <div class="pagination">
                <span :class="{act: isPrevActive}" @click="scrollOtziv('prev')"><span></span></span>
                <span :class="{act: isNextActive}" @click="scrollOtziv('next')"><span></span></span>
            </div>
        </div>
    </div>
</template>

<script>

    const axios = require('axios/index');

    import OtziviImgItem from "./components/OtziviImgItem";
    import OtziviTextItem from "./components/OtziviTextItem";

    export default {
        name: "Otzivi",
        components: {OtziviTextItem, OtziviImgItem},
        data: function(){
            return {
                'listOtziviImg': {},
                'listOtziviText': {},
                'idActiveOtziv': 0,
                'isPrevActive': false,
                'isNextActive': true,
                'isClick': false,
            }
        },
        created: function () {
            axios.get('../json/otzivi.json').then((response) => {
                this.listOtziviImg = response.data['list_img'];
                this.listOtziviText = response.data['list_text'];

                for (let i = 0; i < this.listOtziviText.length; i++) {

                    if ( i === 0 ) {
                        this.listOtziviText[i].act = true;
                    } else{
                        this.listOtziviText[i].act = false;
                    }

                }

                if ( this.listOtziviText.length === 1 )
                {
                    this.isNextActive = true;
                }
            });
        },
        methods: {
            scrollOtziv(type){

                if( type === 'next' )
                {
                    this.idActiveOtziv += 1;

                    this.listOtziviText[ this.idActiveOtziv ].act = true;
                    this.listOtziviText[ this.idActiveOtziv - 1 ].act = false;

                    this.isPrevActive = true;

                    if ( this.idActiveOtziv === (this.listOtziviText.length - 1) )
                    {
                        this.isNextActive = false;
                    }
                }

                if( type === 'prev' )
                {
                    this.idActiveOtziv -= 1;

                    this.listOtziviText[ this.idActiveOtziv ].act = true;
                    this.listOtziviText[ this.idActiveOtziv + 1 ].act = false;

                    this.isNextActive = true;

                    if ( this.idActiveOtziv === 0 )
                    {
                        this.isPrevActive = false;
                    }
                }

                this.isClick = true;
                setTimeout(this.closeClick, 500);
            },

            closeClick(){
                this.isClick = false;
            }
        }
    }
</script>

<style scoped>

</style>