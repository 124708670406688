<template>
    <div class="pagination">
        <ul>
            <li
                v-for="slide in listSlider"
                :class="currentSlide(slide)"
                @click="scrollSlide(slide.MIGX_id)"
                :key="slide.MIGX_id"
            ></li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "PaginationList",
        props: [
            "listSlider",
            "isActiveId"
        ],
        methods:{
            currentSlide(slide) {
                return {
                    active : slide.MIGX_id === this.isActiveId
                }
            },
            scrollSlide(current_slide_id){
                this.$emit('scroll-slide', current_slide_id);
            }
        }
    }
</script>

<style scoped>

</style>