<template>
    <div
            class="items"
            :class="{click: isClick}"
    >

        <div
                class="item"
                :class="{active: project.act}"
                v-for="project in listProjects"

                :key="project.MIGX_id"
        >
            <div class="img">
                <img :src="project.img" alt="" />
            </div>
            <div class="description">
                <div class="data">{{project.data}}</div>
                <div class="name"><a :href="project.link">{{project.name}}</a></div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        name: "ProjectItem",
        props: [
            "listProjects",
            "isClick"
        ]
    }
</script>

<style scoped>

</style>