<template>
    <div
            class="items"
            :class="{click: isClick}"
    >
        <div
                class="item"
                v-for="(news_item, index) in listNews"
                :class="{active: news_item.active}"
                :key="news_item.id"
        >
            <div class="img">
                <a :href="news_item.link">
                    <img :src="news_item.img_path" alt="" />
                </a>
            </div>
            <div class="data">{{news_item.data}}</div>
            <div class="name">
                <a :href="news_item.link">{{news_item.name}}</a>
            </div>
            <div class="description">{{news_item.description}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "NewsItem",
        props: [
            "listNews",
            "isClick"
        ]
    }
</script>

<style scoped>

</style>