<template>
    <div id="news" class="block_news">
        <div class="title">Новости</div>

        <NewsItem :list-news="listNews" :is-click="isClick" />
        <NewsPagination :no-show-pagination="noShowPagination" :count-pagination="countPagination" :is-active-id="isActiveId" @scroll-news="scrollNews" />
    </div>
</template>

<script>

    const axios = require('axios/index');
    import {isMax768} from './main.js';

    import NewsItem from "./components/NewsItem";
    import NewsPagination from "./components/NewsPagination";

    export default {
        name: "News",
        components: {
            NewsItem,
            NewsPagination
        },
        data: function () {
            return {
                "listNews": {},
                'countNews': 4,
                'countPagination': 0,
                'isClick': false,
                'isActiveId': 0,
                'noShowPagination': false
            }
        },
        created: function () {
            axios.get('../json/news.json').then((response) => {
                this.listNews = response.data;

                // если новостей меньше 4, пагинацию не отображаем
                if ( this.listNews.length <= 4 ) {
                    this.noShowPagination = true;
                }

                // > меняем кол-во новостей в строчки
                if ( !isMax768 ) {
                    this.countNews = 1;
                }

                for (let i = 0; i < this.countNews; i++) {
                    this.listNews[i].active = true;
                }
                // <

                this.isActiveId = this.listNews[0].id;
                this.countPagination = this.listNews.length - this.countNews + 1;
            });
        },
        methods: {
            scrollNews(index){

                this.isClick = true;
                this.isActiveId = index;

                for (let i = 0; i < this.listNews.length; i++) {
                    this.listNews[i].active = false;
                }

                for (let i = index; i < (this.countNews + index); i++) {
                    this.listNews[i].active = true;
                }

                setTimeout(this.closeCick, 500);
            },
            closeCick(){
                this.isClick = false;
            }
        }
    }
</script>

<style scoped>

</style>