<template>
    <div class="items">
        <a :href="slide.link" class="item"
             v-for="slide in listSlider"
             :class="currentSlide(slide)"
             :style="{backgroundImage: 'url(' + slide.img + ')'}"
             :key="slide.MIGX_id"
        >
            <div class="wrapper">
                <div class="text">{{slide.description}}</div>
            </div>
        </a>
    </div>
</template>

<script>
    export default {
        name: "SliderItem",
        props: [
            "listSlider",
            "isActiveId"
        ],
        methods:{
            currentSlide: function (slide) {
                return {
                    active : slide.MIGX_id === this.isActiveId
                }
            }
        }
    }
</script>

<style scoped>

</style>