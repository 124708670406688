<template>
    <div class="block_client" id="client">
        <div class="title">Наши партнеры</div>
        <div class="items">
            <span class="arrow left" :class="{active: isActiveArrowLeft}" @click="scrollClient('left')"></span>
            <span class="arrow right" :class="{active: isActiveArrowRight}" @click="scrollClient('right')"></span>

            <ClientItem :list-client="listClient" :is-click="isClick" />
        </div>
    </div>
</template>

<script>

    const axios = require('axios');

    import ClientItem from './components/ClientItem';
    import {isMax768} from './main.js';

    export default {
        name: "Client",
        components: {ClientItem},
        data: function () {
            return{
                'listClient': {},
                'countClient': 5,
                'isActiveArrowLeft': false,
                'isActiveArrowRight': true,
                'prevClientId': 0,
                'nextClientId': 0,
                'isClick': false,
            }
        },
        created: function () {
            axios.get('../json/clients.json').then((response) => {

                this.listClient = response.data;

                // > меняем кол-во отзывов в строчки
                if ( !isMax768 ) {
                    this.countClient = 3;
                }

                for (let i = 0; i < this.listClient.length; i++) {

                    if ( i <= (this.countClient - 1) ) {
                        this.listClient[i].act = true;
                    } else{
                        this.listClient[i].act = false;
                    }
                }
                // <

                this.countClient -= 1;
                this.nextClientId = this.countClient;

                if( this.listClient.length <= this.countClient )
                {
                    this.isActiveArrowRight = false;
                    this.nextClientId = 0;
                }

            });
        },
        methods:{
            scrollClient(type){

                this.isClick = true;

                if( type === 'right' )
                {
                    this.nextClientId += 1;
                    this.prevClientId += 1;

                    this.listClient[this.nextClientId - (this.countClient + 1)].act = false;
                    this.listClient[this.nextClientId].act = true;

                    if ( this.isActiveArrowLeft === false )
                    {
                        this.isActiveArrowLeft = true;
                    }

                    if ( this.listClient.length === (this.nextClientId + 1) )
                    {
                        this.isActiveArrowRight = false;
                    }
                }

                if ( type === 'left' )
                {
                    this.prevClientId -= 1;
                    this.nextClientId -= 1;

                    this.listClient[this.prevClientId + (this.countClient + 1)].act = false;
                    this.listClient[this.prevClientId].act = true;

                    if ( this.isActiveArrowRight === false )
                    {
                        this.isActiveArrowRight = true;
                    }

                    if ( this.prevClientId === 0 )
                    {
                        this.isActiveArrowLeft = false;
                    }
                }


                setTimeout(this.closeCick, 500);
            },

            closeCick(){
                this.isClick = false;
            }
        }
    }
</script>

<style scoped>

</style>