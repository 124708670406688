<template>
    <div
            class="items"
            :class="{click: isClick}"
    >
        <div
                class="item"
                :class="{active: otziv_item.act}"
                v-for="(otziv_item, index) in listOtziviText"
                :title="otziv_item.MIGX_id"
                :key="otziv_item.MIGX_id"
        >
            <div class="data">{{otziv_item.data}}</div>
            <div class="name">{{otziv_item.name}}</div>
            <div class="description">{{otziv_item.description}}</div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "OtziviTextItem",
        props: [
            "listOtziviText",
            "isClick"
        ]
    }
</script>

<style scoped>

</style>