<template>
    <div
            class="items"
            :class="{click: isClick}"
    >
        <div
                class="item"
                v-for="sert in listSertificat"
                :class="{active: sert.act}"
                :key="sert.MIGX_id"
        >
            <img :src="sert.img_small" :alt="sert.MIGX_id" />
        </div>
    </div>
</template>

<script>
    export default {
        name: "SertificatItem",
        props: [
            "listSertificat",
            "isClick"
        ]
    }
</script>

<style scoped>

</style>